



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GameModule } from '@/store/modules'
import { GM, Game } from '@/api'

@Component
export default class RefundItemModal extends Vue {
  @GameModule.State('characters') charactersFromAPI

  showRefundItemModal = false
  log = ''
  selectedItem = null
  items: any[] = [{ value: null, text: 'Select an item to generate' }]
  success = false
  message = ''
  characterToSend: Nullable<number> = null
  characters: any[] = [{ value: null, text: 'Select a GM character' }]

  @Watch('log')
  async onItemChange(newItem) {
    try {
      this.resetItems()
      this.resetValidationState()

      const item = JSON.parse(newItem)

      if (Array.isArray(item)) {
        const itemNames = await Game.getItemsNames(item.map(e => e.itemInstance.holdingVNum || e.itemInstance.itemVNum))

        const items = item.map(e => {
          const { itemVNum, holdingVNum } = e.itemInstance

          return {
            value: e.itemInstance,
            text: `[${itemVNum}] ${itemNames.find(e => e.vnum === holdingVNum || e.vnum === itemVNum)?.name}`,
          }
        })

        this.items = [
          ...this.items,
          ...items,
        ]

        this.selectedItem = items[0].value
      } else {
        const itemName = (await Game.getItemsNames([item.itemVNum]))[0]

        const selectedItem: any = {
          value: item,
          text: `[${itemName.vnum}] - ${itemName.name}`
        }

        this.items = [
          ...this.items,
          selectedItem
        ]

        this.selectedItem = selectedItem.value
      }
    }
    catch(e) {
      this.message = 'The item is incorrect'
      console.log('Error parsing item')
    }
  }

  resetItems() {
    this.selectedItem = null
    this.items = [{ value: null, text: 'Select an item to generate' }]
  }

  resetCharacters() {
    this.characterToSend = null
    this.characters = [{ value: null, text: 'Select a GM character' }]
  }

  resetValidationState() {
    this.success = false
    this.log = ''
  }

  resetInputs() {
    this.message = ''

    this.resetValidationState()
    this.resetItems()
    this.resetCharacters()
  }

  canGenerateItem() {
    return this.selectedItem && this.characterToSend
  }

  mounted() {
    this.$root.$on('openRefundItemModal', () => this.showRefundItemModal = true)

    const characters = this.charactersFromAPI.map(c => {
      return {
        value: c.id,
        text: c.name
      }
    })
  
    this.characters = [
      ...this.characters,
      ...characters,
    ]
  }

  async createItem(e) {
    e.preventDefault()

    const { success } = await GM.generateItemFromLogs({
      characterId: this.characterToSend!,
      item: {
        partnerSkills: [],
        equipmentOptions: [],
        // @ts-ignore
        ...this.selectedItem,
      },
    })

    if (!success) {
      this.success = false
      this.message = 'Error when generating item. Make sure you selected a GM Character.'

      return
    }

    this.success = true
    this.message = 'Item has been sent on your packets'
  }
}
